<template>
    <div>

        <div class="br-subleft" v-bind:style="{left: leftMargin + 'px'}" style="height: 100%; overflow-y: scroll;">
            <div class="pd-10">
                <p class="tx-center tx-white">{{files.length}} Files <span v-if="restrictedFiles.length > 0">(+ {{ restrictedFiles.length }} Restricted Files)</span></p>
                <a href="javascript:void(0);" class="btn btn-teal bd-0 btn-compose" @click="search">Refresh</a>
            </div>

            <nav v-if="$can('claims-manage-team') && restrictedFiles.length > 0" class="nav br-nav-mailbox flex-column">
                <p><strong>Restricted Documents</strong></p>
                <a v-for="file in restrictedFiles" @click="selectFile(file)" :key="file" :class="{'selectedfile': selectedFile === file}" href="javascript:void(0);" class="nav-link mg-b-10 tx-warning"><i class="fa fa-file-pdf tx-danger"></i> {{file}}</a>
            </nav>

            <nav class="nav br-nav-mailbox flex-column">
                <p><strong>Documents</strong></p>
                <a v-for="file in files" @click="selectFile(file)" :key="file" :class="{'selectedfile': selectedFile === file}" href="javascript:void(0);" class="nav-link mg-b-10"><i class="fa fa-file-pdf tx-danger"></i> {{file}}</a>
            </nav>

        </div>

        <div style="margin-left: 230px;">

            <div class="row" v-show="selectedFile !== null">
                <div class="col-7" id="canvasContainer">
                    
                    <iframe v-if="currentSource !== null" :src="currentSource" width="100%" height="1000px" min-height="500px;" style="min-height: 90vh;"></iframe>
                    
                </div>
                <div class="col-5">

                    <div class="card bd-0 mg-b-20">
                        <div class="card-header bg-primary tx-white">
                            <ul class="nav nav-tabs nav-tabs-for-dark card-header-tabs">
                                <li class="nav-item">
                                    <a class="nav-link text-white bd-0 pd-y-8" v-bind:class="{'active pd-y-8': tabs.main}" href="javascript:void(0);" @click="selectTab('main')">DVLA Response</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link text-white bd-0 pd-y-8" v-bind:class="{'active pd-y-8': tabs.tol}" href="javascript:void(0);" @click="selectTab('tol')">Transfer of Liability</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link text-white bd-0 pd-y-8" v-bind:class="{'active pd-y-8': tabs.other}" href="javascript:void(0);" @click="selectTab('other')">Other</a>
                                </li>
                            </ul>
                        </div><!-- card-header -->
                        <div class="card-body">

                            <p v-if="reason"><strong>DVLA Scanning Failed:</strong> {{reason}}</p>

                            <dvla-document ref="dvlaDocument" @vfocus="(value) => setTarget(value, 'dvlaDocument')" @refresh="refresh" @getcommon="getCommonDetails" :companies="commonDetails" :file="selectedFile" :scannedData="responseData" v-if="tabs.main" @ocrreference="ocrReference"></dvla-document>
                            <transfer-document ref="transferDocument" :scannedData="responseData" @vfocus="(value) => setTarget(value, 'transferDocument')" @refresh="refresh" @getcommon="getCommonDetails" :file="selectedFile" :companies="commonDetails" v-if="tabs.tol"></transfer-document>
                            <other-document ref="otherDocument" :scannedData="responseData" @vfocus="(value) => setTarget(value, 'otherDocument')" @refresh="refresh" @getcommon="getCommonDetails" :file="selectedFile" v-if="tabs.other"></other-document>

                            <button class="btn btn-sm btn-warning mg-t-20" @click="restrict">Restrict Document</button>

                        </div>
                    </div>

                </div>
            </div>

        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex";
import DvlaDocument from "./DvlaDocument";
import TransferDocument from "./TransferDocument";
import OtherDocument from "./OtherDocument";
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "Documents",
    components: {
        OtherDocument,
        TransferDocument,
        DvlaDocument
    },
    data(){
        return {
            files: [],
            restrictedFiles: [],
            selectedFile: null,
            currentSource: null,
            tabs: {
                main: true,
                tol: false,
                other: false
            },
            currentPage: 1,
            pageCount: 0,
            canvas: null,
            ctx: null,
            baseImage: null,
            storedRects: [],
            clearRect: false,
            rect: {},
            worker: null,
            ocrTarget: {
                component: null,
                value: null
            },
            currentRotation: 0,
            commonDetails: [],
            responseData: {
                name: "",
                address_one: "",
                address_two: "",
                address_three: "",
                address_four: ""
            },
            reasons: {}
        }
    },
    mounted(){
        this.search();
        this.getCommonDetails();
    },
    beforeUnmount(){
        if(this.currentSource){
            window.URL.revokeObjectURL(this.currentSource);
        }
    },
    methods: {
        getCommonDetails(){
            axios.get(`https://api.varsanpr.com/api/documents/common?minified=true`, {
                headers: authHeader()
            })
            .then(response => {
                this.commonDetails = response.data.details;
            })
            .catch(error => {
                this.$error("Failed fetching details.", error);
                console.error(error);
            });
        },
        setTarget(value, component){
            this.ocrTarget.component = component;
            this.ocrTarget.value = value;
        },
        rotateClockwise(){
          let current = this.currentRotation;
          current += 90;
          if(current > 360){
              current -= 360;
          }
          this.currentRotation = current;
        },
        rotateAntiClockwise(){
            let current = this.currentRotation;
            current -= 90;
            if(current > -360){
                current += 360;
            }
            this.currentRotation = current;
        },
        async ocrReference(src, rect){
            const isFirefox = /Firefox/.test(navigator.userAgent) || typeof InstallTrigger !== 'undefined';
            try {
                await this.worker.terminate();
            }
            catch (e) {}
            try {
                this.worker = Tesseract.createWorker({
                    'workerBlobURL': isFirefox ? false : true,
                    logger(report) {
                        if (report.status === 'recognizing text') {
                            // document.getElementById('recognize').value = report.progress;
                        }
                        else if (report.status === 'loaded language traineddata') {
                            // document.getElementById('lang').value = report.progress;
                        }
                    }
                });

                const lang = 'eng';
                await this.worker.load();
                await this.worker.loadLanguage(lang);
                await this.worker.initialize(lang);
                await this.worker.setParameters({
                    tessedit_pageseg_mode: Tesseract.PSM.SINGLE_BLOCK,
                    tessedit_ocr_engine_mode: Tesseract.DEFAULT
                });
                const o = (await this.worker.recognize(src)).data;

                // document.getElementById('recognize').value = 1;
                const parser = new DOMParser();
                const doc = parser.parseFromString(o.hocr, 'text/html');
                // const result = document.getElementById('result');
                // for (const child of [...doc.body.childNodes]) {
                //     result.appendChild(child);
                // }
                // result.value = o.text;
                let tArr = o.text.split('\n');
                tArr.pop();
                let nArr = [];
                tArr.forEach((v) => {
                    if(v.trim() !== '' && v.trim().length > 0){
                        nArr.push(v);
                    }
                })

                let lastLine = nArr[nArr.length - 1].split(' ');
                nArr.pop();

                nArr.push(lastLine[0])
                lastLine.shift();
                nArr.push(lastLine.join(' '));

                if(this.ocrTarget.component !== null && this.ocrTarget.value !== null){

                    if(this.ocrTarget.component === 'dvlaDocument' && this.ocrTarget.value === 'responseData.name' && nArr.length > 1){

                        this.$refs[this.ocrTarget.component].responseData.name = nArr[0] || "";
                        this.$refs[this.ocrTarget.component].responseData.address_one = nArr[1] || "";
                        this.$refs[this.ocrTarget.component].responseData.address_two = nArr[2] || "";
                        this.$refs[this.ocrTarget.component].responseData.address_three = nArr[3] || "";
                        this.$refs[this.ocrTarget.component].responseData.address_four = nArr[4] || "";

                    }else{

                        if(this.ocrTarget.value.split('.').length > 1){
                            this.$refs[this.ocrTarget.component][this.ocrTarget.value.split('.')[0]][this.ocrTarget.value.split('.')[1]] = o.text;
                        }else{
                            this.$refs[this.ocrTarget.component][this.ocrTarget.value] = o.text;
                        }

                    }

                }

                // if (o.text.trim() === '') {
                    // result.textContent = 'No text was detected';
                // }
                // else {
                    // document.getElementById('copy').disabled = false;
                // }
                await this.worker.terminate();
            }
            catch (e) {
                console.warn(e);
            }
        },
        pageLoaded(event){
            this.currentPage = event;
        },
        nextPage(){
            if(this.currentPage < this.pageCount){
                this.currentPage++;
            }
        },
        previousPage(){
            if(this.currentPage > 1){
                this.currentPage--;
            }
        },
        search(){
            axios.get(`https://api.varsanpr.com/api/documents`, {
                headers: authHeader()
            })
            .then(response => {
                this.files = response.data.files;
                this.restrictedFiles = response.data.restrictedFiles;
                this.reasons = response.data.reasons;
                this.selectFile(this.files[0]);
            })
            .catch(error => {
                this.$error("Failed loading documents!", error);
            });
        },
        refresh(){
          let index = this.files.indexOf(this.selectedFile);
            this.files = this.files.filter((f) => {
                return f !== this.selectedFile;
            });
            this.selectFile(this.files[index]);
        },
        selectFile(file){
            this.clearRect = false;
            this.responseData = {
                name: "",
                address_one: "",
                address_two: "",
                address_three: "",
                address_four: ""
            }
            this.getData(file);
            if(this.currentSource){
                window.URL.revokeObjectURL(this.currentSource);
            }
            axios.get(`https://api.varsanpr.com/api/documents/${file}`, {
                responseType: 'arraybuffer',
                headers: authHeader()
            })
                .then(response => {
                    let blob = new Blob([response.data], {type: 'application/pdf'});
                    this.currentSource = window.URL.createObjectURL(blob);
                    this.selectedFile = file;
                    this.currentPage = 1;
                })
                .catch(error => {
                    this.$error("Failed to load file!", error);
                    this.currentSource = null;
                })
        },
        restrict(){
            if(!this.selectedFile) return;
            axios.post(`https://api.varsanpr.com/api/documents/${this.selectedFile}/restrict`, {}, {
                headers: authHeader()
            })
            .then(response => {
                this.$success("Document restricted!");
                this.restrictedFiles.push(this.selectedFile);
                this.files = this.files.filter((f) => {
                    return f !== this.selectedFile;
                });
                this.selectedFile = null;
                this.currentSource = null;
            })
            .catch(error => {
                this.$error("Failed to restrict document!", error);
            });
        },
        getData(file){
            axios.get(`https://api.varsanpr.com/api/documents/${file}/data`, {
                headers: authHeader()
            })
            .then(response => {
                this.responseData.address_one = response.data.address_one || "";
                this.responseData.address_two = response.data.address_two || "";
                this.responseData.address_three = response.data.address_three || "";
                this.responseData.address_four = response.data.address_four || "";
                this.responseData.name = response.data.name;

                if(this.responseData.address_two.length < 1){
                    this.responseData.address_two = response.data.postcode;
                }else if(this.responseData.address_three.length < 1){
                    this.responseData.address_three = response.data.postcode;
                }else if(this.responseData.address_four.length < 1){
                    this.responseData.address_four = response.data.postcode;
                }else{
                    this.responseData.address_four = response.data.postcode;
                }
                
            })
            .catch(error => {
                console.log(error);
            });
        },  
        selectTab(tab){
            for (let t in this.tabs){
                this.tabs[t] = false;
            }

            this.tabs[tab] = true;
        }
    },
    computed: {
        leftMargin: function(){
            return this.$store.state.auth.sidebar ? 230 : 0;
        },
        reason: function(){
            return this.reasons[this.selectedFile] || "";
        }
    },
}
</script>

<style scoped>
    .selectedfile {
        color: green;
        text-decoration: underline;
    }
    .card-header-tabs.nav-tabs-for-dark .nav-link.active {
        border-bottom: 2px solid white;
        background-color: transparent;
    }

    .card-header-tabs.nav-tabs-for-dark .nav-link:hover {
        background-color: transparent;
        border-bottom: 2px solid white;
        font-weight: bold;
    }
</style>

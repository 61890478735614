<template>
    <div>
        <p class="tx-bold tx-warning">Please make sure that the document to the left is a DVLA Response before filling out the below.</p>

        <div class="form-group">
            <label>Reference Number</label>
            <input v-on:keypress.enter="searchForClaim" v-on:focusin="$emit('vfocus', 'reference')" type="text" v-model.trim="reference" class="form-control form-control-dark">
        </div>

        <submit-button :background="'btn btn-sm btn-primary'" :clicked="searching" :show="reference.length > 0" @click="searchForClaim" :text="'Find Claim'"></submit-button>

        <p v-if="searchError" class="tx-danger">Unable to find a claim with the reference number provided. Please try again.</p>
        <div v-if="!searchError && claimType !== ''" class="mg-t-10">
            <ul class="list-group mg-b-10">
                <li class="list-group-item rounded-top-0">
                    <p class="mg-b-0"><strong class="tx-white tx-medium">Status</strong> <span class="tx-white-4 ft-right">{{claim.status}}</span></p>
                </li>
                <li class="list-group-item">
                    <p class="mg-b-0"><strong class="tx-white tx-medium">Type</strong> <span class="tx-white-4 ft-right">{{claimType}}</span></p>
                </li>
                <li class="list-group-item">
                    <p class="mg-b-0"><strong class="tx-white tx-medium">Value</strong> <span class="tx-white-4 ft-right">£{{claimValue}}</span></p>
                </li>
                <li class="list-group-item">
                    <p class="mg-b-0"><strong class="tx-white tx-medium">Site Comments</strong> <span class="tx-white-4 ft-right">{{claim.comments}}</span></p>
                </li>
                <li class="list-group-item">
                    <p class="mg-b-0"><strong class="tx-white tx-medium">Site Name</strong> <span class="tx-white-4 ft-right">{{claim.site.name}}</span></p>
                </li>
                <li class="list-group-item rounded-bottom-0">
                    <p class="mg-b-0"><strong class="tx-white tx-medium">Vehicle Registration</strong> <span class="tx-white-4 ft-right">{{claim.vehicle.plate}}</span></p>
                </li>
                <li class="list-group-item rounded-bottom-0">
                    <p class="mg-b-0"><strong class="tx-white tx-medium">Date of Event</strong> <span class="tx-white-4 ft-right">{{created_at}}</span></p>
                </li>
            </ul>

            <div v-if="!response">
                <button class="btn btn-sm btn-primary mg-b-10 mg-r-10" @click="assignAsEvidence(false, false)">Assign as Evidence</button>
                <button class="btn btn-sm btn-danger mg-r-10 mg-b-10" @click="assignAsEvidence(true, false)">Assign as Evidence & Cancel</button>
                <button class="btn btn-sm btn-warning mg-r-10 mg-b-10" @click="assignAsEvidence(false, true)">Assign as Evidence & Clerical Records</button>
                <button v-if="claim.status === 'At DVLA' || claim.status === 'Clerical Records'" class="btn btn-sm btn-primary mg-r-10 mg-b-10" @click="showResponse">Process as Response</button>
            </div>
            <div v-else>

                <p class="tx-bold tx-white">Process Claim Data</p>

                <div class="form-group row mg-b-20">
                    <select id="dvlaCompanySelect" v-model="common" class="form-control form-control-dark select2" v-select2>
                        <option v-for="company in companies" v-bind:value="company.id">{{ company.name }}</option>
                    </select>

                    <button class="btn btn-sm btn-primary mg-l-10 float-right mg-t-10" @click="fillDetails">Fill Details</button>
                </div>

                <div class="form-group row">
                    <label class="col-md-3 col-form-label">Name <span class="text-danger">*</span></label>
                    <div class="col-md-9">
                        <input type="text" autocomplete="off" v-on:focusin="$emit('vfocus', 'responseData.name')" name="name" v-model="responseData.name" class="form-control form-control-dark">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-form-label">Address One <span class="text-danger">*</span></label>
                    <div class="col-md-9">
                        <input type="text" autocomplete="off" v-on:focusin="$emit('vfocus', 'responseData.address_one')" name="address_one" v-model="responseData.address_one" class="form-control form-control-dark">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-form-label">Address Two <span class="text-danger">*</span></label>
                    <div class="col-md-9">
                        <input type="text" autocomplete="off" v-on:focusin="$emit('vfocus', 'responseData.address_two')" name="address_two" v-model="responseData.address_two" class="form-control form-control-dark">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-form-label">Address Three <span class="text-danger">*</span></label>
                    <div class="col-md-9">
                        <input type="text" autocomplete="off" v-on:focusin="$emit('vfocus', 'responseData.address_three')" name="address_three" v-model="responseData.address_three" class="form-control form-control-dark">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-form-label">Address Four</label>
                    <div class="col-md-9">
                        <input type="text" autocomplete="off" v-on:focusin="$emit('vfocus', 'responseData.address_four')" name="address_four" v-model="responseData.address_four" class="form-control form-control-dark">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-form-label">Special Notes</label>
                    <div class="col-md-9">
                        <input type="text" name="special_notes" v-on:focusin="$emit('vfocus', 'responseData.special_notes')" v-model="responseData.special_notes" class="form-control form-control-dark">
                    </div>
                </div>

                <button class="btn btn-sm btn-success" @click="create">Save & Submit</button>
                <button class="btn btn-sm btn-danger mg-l-10" @click="response = false;">Cancel</button>

            </div>

            <hr style="border-color: white;"/>

            <claim-notes :claim="claim" class="mg-t-20" :client_id="claim.site.client_id"></claim-notes>
        </div>
    </div>
</template>

<script>
import SubmitButton from "../../components/SubmitButton";
import Swal from 'sweetalert2';
import ClaimNotes from "../claims/components/ClaimNotes";
import {DateTime} from "luxon";
import axios from "axios";
import authHeader from "@/services/auth-header";

export default {
    name: "DvlaDocument",
    components: {ClaimNotes, SubmitButton},
    props: {
        file: String,
        ocr: {
            type: String,
            required: false
        },
        companies: Array,
        scannedData: {
            type: Object,
            required: false
        }
    },
    data(){
        return {
            reference: "",
            searching: false,
            searchError: false,
            claim: {},
            created_at: "",
            response: false,
            responseData: {
                name: "",
                address_one: "",
                address_two: "",
                address_three: "",
                address_four: "",
                special_notes: "",
                claim_id: null,
                dvla_reference: "0",
            },
            common: null
        }
    },
    methods: {
        showResponse(){
            this.response = true;
            if(this.scannedData?.name?.length > 0){
                this.responseData.name = this.scannedData.name;
            }
            if(this.scannedData?.address_one?.length > 0){
                this.responseData.address_one = this.scannedData.address_one;
            }
            if(this.scannedData?.address_two?.length > 0){
                this.responseData.address_two = this.scannedData.address_two;
            }
            if(this.scannedData?.address_three?.length > 0){
                this.responseData.address_three = this.scannedData.address_three;
            }
            if(this.scannedData?.address_four?.length > 0){
                this.responseData.address_four = this.scannedData.address_four;
            }
            $('#dvlaCompanySelect').select2();
        },
        fillDetails(){
            axios.get(`https://api.varsanpr.com/api/documents/common?id=${this.common}`, {
                headers: authHeader()
            })
            .then(response => {
                this.responseData.name = response.data.details.name;
                this.responseData.address_one = response.data.details.address_one;
                this.responseData.address_two = response.data.details.address_two;
                this.responseData.address_three = response.data.details.address_three;
                this.responseData.address_four = response.data.details.address_four;

                if(this.responseData.address_two.length < 1){
                    this.responseData.address_two = response.data.details.postcode;
                }else if(this.responseData.address_three.length < 1){
                    this.responseData.address_three = response.data.details.postcode;
                }else if(this.responseData.address_four.length < 1){
                    this.responseData.address_four = response.data.details.postcode;
                }

            })
            .catch(error => {
                this.$error("Failed fetching details.", error);
                console.error(error);
            });
        }, 
        searchForClaim(){
            this.searching = true;
            this.searchError = false;
            axios.get(`https://api.varsanpr.com/api/claims/find?reference=${this.reference}`, {
                headers: authHeader()
            })
            .then(response => {
                response.data.claim.notes.forEach((n) => {
                    n.created_at = DateTime.fromSeconds(n.created_at).toFormat('dd/MM/yyyy HH:mm:ss');
                })
                this.claim = response.data.claim
                this.created_at = response.data.created_at;
            })
            .catch(error => {
                this.$error("Unable to find claim with that reference", error);
                this.searchError = true;
            })
            .finally(() => {
                this.searching = false;
            });
        },
        assignAsEvidence(cancel = false, clerical = false){
            // document.getElementById('drawingCanvas').style.display = 'none';
            Swal.fire({
                title: 'Are you sure?',
                text: "This will add the file to the claim as a DVLA Response",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, assign it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(`https://api.varsanpr.com/api/documents/${this.file}/dvla`, {
                        claim_id: this.claim.id,
                        client_id: this.claim.site.client_id,
                        cancel: cancel,
                        clerical: clerical
                    }, {
                        headers: authHeader()
                    })
                    .then(response => {
                        if(response.data.success){
                            this.$success('This file has been assigned as evidence');
                            // Swal.fire(
                            //     'Assigned!',
                            //     'This file has been assigned as evidence.',
                            //     'success'
                            // )
                            this.$emit('refresh');
                        }else{
                            throw new Error('Failed to assign error.');
                        }
                    })
                    .catch(error => {
                        Swal.fire(
                            'Oops!',
                            'Unable to assign as evidence.',
                            'error'
                        )
                    })
                    .finally(() => {
                        // document.getElementById('drawingCanvas').style.display = 'block';
                    })
                }else{
                    // document.getElementById('drawingCanvas').style.display = 'block';
                }
            })
        },
        create(){
            axios.post(`https://api.varsanpr.com/api/documents/${this.file}/response`, {
                ...this.responseData,
                claim_id: this.claim.id,
                client_id: this.claim.site.client_id
            }, {
                headers: authHeader()
            })
                .then(response => {
                    console.log(response.data);
                    if(response.data.success){
                        this.reference = "";
                        this.responseData.name = "";
                        this.responseData.claim_id = null;
                        this.responseData.address_one = "";
                        this.responseData.address_two = this.responseData.address_three = this.responseData.address_four = "";
                        this.responseData.special_notes = "";
                        this.responseData.dvla_reference = "0";
                        this.$success('This file has been assigned as evidence and a mail job created');
                        // Swal.fire(
                        //     'Processed!',
                        //     'This file has been assigned as evidence and a mail job created.',
                        //     'success'
                        // )
                        this.$emit('refresh');
                    }
                })
                .catch(error => {
                    console.log(error);
                    Swal.fire(
                        'Oops!',
                        'Unable to assign as evidence or process data.',
                        'error'
                    )
                });
        }
    },
    computed: {
        claimType: function(){
            switch(this.claim.type){
                case 'driveoff':
                    return 'Drive Off';
                case 'escalated':
                    return 'Drive Off (No Means of Payment)';
                case 'nmop':
                    return 'No Means of Payment';
                case 'parking':
                    return 'Parking';
                case 'cnaf': 
                    return 'Customer Not at Fault';
                case 'cnafe':
                    return 'Drive Off (Customer Not at Fault)';
                case 'evparking':
                    return 'EV Parking';
                default:
                    return '';
            }
        },
        claimValue: function(){
            switch(this.claim.type){
                case 'driveoff':
                case 'nmop':
                case 'escalated':
                case 'cnaf':
                case 'cnafe':
                    return parseFloat(parseFloat(this.claim.value || 0) + parseFloat(this.claim.value_second || 0)).toFixed(2);
                case 'parking':
                case 'evparking':
                    return 60.00;
                default:
                    return 0.00;
            }
        }
    },
    watch: {
        file: function(val, oldval){
            this.reference = "";
            this.searching = false;
            this.searchError = false;
            this.claim= {};
            this.response= false;
            this.responseData = {
                name: "",
                address_one: "",
                address_two: "",
                address_three: "",
                address_four: "",
                special_notes: "",
                claim_id: null,
                dvla_reference: "0",
            };
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <div>
        <div class="form-group">
            <label>Reference Number</label>
            <input v-on:keypress.enter="searchForClaim" type="text" v-model="reference" class="form-control form-control-dark">
        </div>

        <submit-button :background="'btn btn-sm btn-primary'" :clicked="searching" :show="reference.length > 0" @click="searchForClaim" :text="'Find Claim'"></submit-button>
        <button class="btn btn-sm btn-danger mg-l-10" @click="deleteEvidence">Delete Document</button>

        <p v-if="searchError" class="tx-danger">Unable to find a claim with the reference number provided. Please try again.</p>
        <div v-if="!searchError && claimType !== ''" class="mg-t-10">
            <ul class="list-group mg-b-10">
                <li class="list-group-item rounded-top-0">
                    <p class="mg-b-0"><strong class="tx-white tx-medium">Status</strong> <span class="tx-white-4 ft-right">{{claim.status}}</span></p>
                </li>
                <li class="list-group-item">
                    <p class="mg-b-0"><strong class="tx-white tx-medium">Type</strong> <span class="tx-white-4 ft-right">{{claimType}}</span></p>
                </li>
                <li class="list-group-item">
                    <p class="mg-b-0"><strong class="tx-white tx-medium">Value</strong> <span class="tx-white-4 ft-right">£{{claimValue}}</span></p>
                </li>
                <li class="list-group-item">
                    <p class="mg-b-0"><strong class="tx-white tx-medium">Site Comments</strong> <span class="tx-white-4 ft-right">{{claim.comments}}</span></p>
                </li>
                <li class="list-group-item">
                    <p class="mg-b-0"><strong class="tx-white tx-medium">Site Name</strong> <span class="tx-white-4 ft-right">{{claim.site.name}}</span></p>
                </li>
                <li class="list-group-item rounded-bottom-0">
                    <p class="mg-b-0"><strong class="tx-white tx-medium">Vehicle Registration</strong> <span class="tx-white-4 ft-right">{{claim.vehicle.plate}}</span></p>
                </li>
                <li class="list-group-item rounded-bottom-0">
                    <p class="mg-b-0"><strong class="tx-white tx-medium">Date of Event</strong> <span class="tx-white-4 ft-right">{{created_at}}</span></p>
                </li>
            </ul>

            <div>
                <button class="btn btn-sm btn-primary" @click="assignAsEvidence(false)">Assign as Evidence</button>
                <button class="btn btn-sm btn-danger mg-l-10" @click="assignAsEvidence(true)">Assign as Evidence & Cancel</button>
                <button v-if="claim.status !== 'Cancelled'" class="btn btn-sm btn-danger mg-l-10" @click="assignAsEvidence">Cancel Claim</button>
            </div>

            <hr style="border-color: white;"/>

            <claim-notes :claim="claim" class="mg-t-20" :client_id="claim.site.client_id"></claim-notes>
        </div>
    </div>
</template>

<script>
import SubmitButton from "../../components/SubmitButton";
import Swal from "sweetalert2";
import ClaimNotes from "../claims/components/ClaimNotes";
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "OtherDocument",
    components: {ClaimNotes, SubmitButton},
    props: {
        file: String,
    },
    data(){
        return {
            reference: "",
            searching: false,
            searchError: false,
            claim: {},
            created_at: ""
        }
    },
    methods: {
        searchForClaim() {
            this.searching = true;
            this.searchError = false;
            axios.get(`https://api.varsanpr.com/api/claims/find?reference=${this.reference}`, {
                headers: authHeader()
            })
                .then(response => {
                    this.claim = response.data.claim
                    this.created_at = response.data.created_at;
                })
                .catch(error => {
                    this.$error("Unable to find claim with that reference", error);
                    this.searchError = true;
                })
                .finally(() => {
                    this.searching = false;
                });
        },
        async assignAsEvidence(cancel = false){
            // document.getElementById('drawingCanvas').style.display = 'none';
            const {value: type} = await Swal.fire({
                title: `Select a type of Evidence`,
                input: 'select',
                inputOptions: {
                    "additional_information": "Additional Information",
                    "other": "Other Evidence",
                    "till_receipt": "Till Receipt",
                    "vehicle_image": "Vehicle Image",
                    "transfer_of_liability": "Transfer of Liability",
                    "dvla_enquiry": "DVLA Enquiry Form",
                    "dvla_response": "DVLA Response"
                },
                inputPlaceholder: 'Select a type',
                showCancelButton: true
            });

            if(type){
                axios.post(`https://api.varsanpr.com/api/documents/${this.file}/evidence`, {
                    claim_id: this.claim.id,
                    client_id: this.claim.site.client_id,
                    type: type,
                    cancel: cancel
                }, {
                    headers: authHeader()
                })
                    .then(response => {
                        if(response.data.success){
                            Swal.fire(
                                'Assigned!',
                                'This file has been assigned to claim.',
                                'success'
                            )
                            this.$emit('refresh');
                        }else{
                            throw new Error('Failed to assign error.');
                        }
                    })
                    .catch(error => {
                        this.$error("Failed adding evidence to claim.", error);
                        Swal.fire(
                            'Oops!',
                            'Unable to assign evidence.',
                            'error'
                        )
                    })
                .finally(() => {
                    // document.getElementById('drawingCanvas').style.display = 'block';
                })
            }else{
                // document.getElementById('drawingCanvas').style.display = 'block';
            }
        },
        deleteEvidence(){
            Swal.fire({
                title: 'Are you sure?',
                text: "This will delete the scanned file?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`https://api.varsanpr.com/api/documents/${this.file}`, {
                        headers: authHeader()
                    })
                        .then(response => {
                            if(response.data.success){
                                Swal.fire(
                                    'Deleted!',
                                    'This file has been deleted.',
                                    'success'
                                )
                                this.$emit('refresh');
                            }else{
                                throw new Error('Failed to assign error.');
                            }
                        })
                        .catch(error => {
                            Swal.fire(
                                'Oops!',
                                'Unable to delete evidence.',
                                'error'
                            )
                        });
                }
            })
        }
    },
    computed: {
        claimType: function(){
            switch(this.claim.type){
                case 'driveoff':
                    return 'Drive Off';
                case 'escalated':
                    return 'Drive Off (No Means of Payment)';
                case 'nmop':
                    return 'No Means of Payment';
                case 'parking':
                    return 'Parking';
                case 'cnaf':
                    return 'Customer Not at Fault';
                case 'cnafe':
                    return 'Customer Not at Fault (Escalated)';
                case 'evparking':
                    return 'EV Parking';
                default:
                    return '';
            }
        },
        claimValue: function(){
            switch(this.claim.type){
                case 'driveoff':
                case 'nmop':
                case 'escalated':
                    return parseFloat(parseFloat(this.claim.value || 0) + parseFloat(this.claim.value_second || 0)).toFixed(2);
                case 'parking':
                    return 60.00;
                default:
                    return 0.00;
            }
        }
    },
    watch: {
        file: function(val, oldval){
            this.reference = "";
            this.searching = false;
            this.searchError = false;
            this.claim= {};
            this.response= false;
            this.responseData = {
                name: "",
                address_one: "",
                address_two: "",
                address_three: "",
                address_four: "",
                special_notes: "",
                claim_id: null,
                dvla_reference: "",
            };
        }
    }
}
</script>

<style scoped>

</style>
